import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";
import DealsList from "./DealsList";
import { getBgColor } from "../../../utils";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const DealsCol = ({
  deals,
  crm_status_name,
  crm_status_id,
  search,
  fetchDeals,
}) => {
  const props = {
    backgroundColor: getBgColor(crm_status_id),
  };
  const classes = useStyles(props);
  const [sortedDeals, setSortedDeals] = useState(deals);
  const [showAddData, setAddShowData] = useState(false);

  useEffect(() => {
    let delayDebounceFn;
    if (!!search?.trim() && deals?.length) {
      delayDebounceFn = setTimeout(() => {
        const sortedDeals = deals?.filter(
          (item) =>
            item.client_fio?.toLowerCase().includes(search.toLowerCase()) ||
            String(item.crm_deal_id).includes(search.toLowerCase()) ||
            item.resident_name?.toLowerCase().includes(search.toLowerCase()) ||
            item.city_name?.toLowerCase().includes(search.toLowerCase()) ||
            item.origin_name?.toLowerCase().includes(search.toLowerCase()) ||
            item.cc_task_text?.toLowerCase().includes(search.toLowerCase())
        );
        setSortedDeals(sortedDeals);
      }, 500);
    } else {
      setSortedDeals(deals);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [search, deals]);

  const toggleShowAddData = () => setAddShowData((prev) => !prev);

  const addData = useMemo(() => {
    const clientChooseLength =
      sortedDeals?.filter((item) => item.is_client_choose === false)?.length ||
      0;
    const flatFilledLength =
      sortedDeals?.filter((item) => item.is_flat_filled === false)?.length || 0;
    return { clientChooseLength, flatFilledLength };
  }, [sortedDeals]);

  return (
    <Box className={classes.col}>
      <Box className={classes.header}>
        <Box className={classes.col_title_wrapper} onClick={toggleShowAddData}>
          <Box className={classes.col_title_left}>
            <Typography className={classes.col_title}>
              {crm_status_name}
            </Typography>
            <Typography className={classes.col_desc}>
              {sortedDeals?.length || 0}
            </Typography>
          </Box>
          <ExpandMoreIcon
            style={{
              color: "#72768F",
              transform: `rotate(${showAddData ? 180 : 0}deg)`,
              transition: "all .3s",
            }}
          />
        </Box>
        <Box
          className={clsx([
            classes.addInfo,
            { [classes.addInfoShow]: showAddData },
          ])}
        >
          <Box className={classes.addInfoItem}>
            Отсутствует выбор клиента: {addData.clientChooseLength}
          </Box>
          <Box className={classes.addInfoItem}>
            Отсутствует номер квартиры: {addData.flatFilledLength}
          </Box>
        </Box>
      </Box>

      <Box className={classes.dealsList}>
        <DealsList
          crmStatusId={crm_status_id}
          sortedDeals={sortedDeals}
          fetchDeals={fetchDeals}
        />
      </Box>
    </Box>
  );
};

export default DealsCol;

const useStyles = makeStyles({
  col: {
    width: 290,
    backgroundColor: "#fff",
    borderRadius: styleConstants.borderRadius,
    border: styleConstants.border,
    "&:not(:last-child)": {
      marginRight: 10,
    },
  },
  addInfo: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
    opacity: 0,
  },
  addInfoShow: {
    maxHeight: "100px",
    opacity: 1,
  },
  addInfoItem: {
    marginTop: 5,
    fontSize: 14,
    color: "#72768F",
  },
  header: {
    backgroundColor: (props) => props.backgroundColor,
    padding: 10,
  },
  col_title_wrapper: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  col_title: {
    fontWeight: 600,
    color: "#72768F",
    marginRight: 5,
  },
  col_title_left: {
    display: "flex",
    alignItems: "center",
  },
  col_desc: {
    fontWeight: 600,
    color: styleConstants.darkGray,
  },
  dealsList: {
    padding: 10,
  },
});
